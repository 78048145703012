<template>
  <div class="home-page">
    <main-jumbotron />
    <card-group
      class="section-scroll"
      :services="templateServices"
      :isHome="true"
      v-scroll-section="{
        route: $route,
        name: 'our-services',
        isDone: services,
      }"
    />
    <our-store
      v-scroll-section="{ route: $route, name: 'our-store', isDone: store }"
    />
    <our-work
      class="pb-5"
      v-scroll-section="{ route: $route, name: 'our-work' }"
    />
    <project />
    <HomeBlogs class="pb-5" />
    <div class="clients-section">
      <clients-slider class="mb-5"></clients-slider>
    </div>

    <subscribe
      class="py-5"
      v-scroll-section="{ route: $route, name: 'subscribe' }"
    />
    <!-- <our-work
      class="pb-5"
      v-scroll-section="{ route: $route, name: 'our-work' }"
    />-->

    <!-- <subscribe
      class="py-5"
      v-scroll-section="{ route: $route, name: 'subscribe' }"
    />-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      orderOverlay: false,
      services: false,
      work: false,
      subscribe: false,
      store: false,
    };
  },

  components: {
    CardGroup: () => import("@/components/Services/CardGroup"),
    OurStore: () => import("@/components/home/OurStore"),
    Subscribe: () => import("@/components/Subscribe"),
    Project: () => import("@/components/Project"),
    OurWork: () => import("@/components/OurWork"),
    HomeBlogs: () => import("@/components/HomeBlogs"),
    ClientsSlider: () => import("@/components/Sliders/Client"),
  },
  computed: {
    ...mapGetters(["GlobalServices"]),
    templateServices() {
      let templateArray = [];
      this.GlobalServices.forEach((service) => {
        templateArray.push({
          id: service.id,
          title: service.name,
          description: service.short_description,
          image: service.main_image,
        });
      });

      return templateArray.slice(0, 8);
    },
  },
};
</script>
